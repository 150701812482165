import * as React from "react"
import { graphql, Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout"

const ProduktionenPage = ({data}) => {
  return (
    <Layout data={data}>
      <GatsbySeo
        title={`Produktionen | ` + data.site.siteMetadata.title + ` | ` + data.site.siteMetadata.slogan}
        description="Produktionen, an denen Marc-Oliver Teschke mitgewirkt hat."
      />
      <h1>Produktionen</h1>
      <div className="view--all-productions">
        <table className="cols-5">
          <thead>
            <tr>
              <th id="view-field-years-table-column" className="views-field views-field-field-years" scope="col">Jahre</th>
              <th id="view-field-production-table-column" className="views-field views-field-field-production" scope="col">Produktion</th>
              <th id="view-field-roles-table-column" className="views-field views-field-field-roles" scope="col">Rollen</th>
              <th id="view-field-production-company-table-column" className="views-field views-field-field-production-company" scope="col">Company</th>
              <th id="view-field-staff-table-column" className="views-field views-field-field-staff" scope="col">Regie</th>
            </tr>
          </thead>
          <tbody>
            {data.allNodeProduction.edges.map((production, i) => {
              let years = production.node.field_years.value;
              if (production.node.field_years.end_value !== production.node.field_years.value) {
                  years += ` - ` + production.node.field_years.end_value;
              }
              let title = production.node.title;
              if (production.node.field_link_in_views === true) {
                title = <Link to={production.node.path.alias}>{title}</Link>;
              }
              return(
                <tr key={i}>
                  <td className="views-field views-field-field-years">{years}</td>
                  <td className="views-field views-field-rendered-entity">
                    <div className="node--production--title_only">{title}</div>
                  </td>
                  <td headers="view-field-roles-table-column" className="views-field views-field-field-roles">
                    <ul>
                      {production.node.field_roles.map((role, i) => {
                        return <li key={i}>{role}</li>
                      })}
                    </ul>
                  </td>
                  <td headers="view-field-production-company-table-column" className="views-field views-field-field-production-company">
                    <ul>
                      {production.node.relationships.field_production_company.map((company, i) => {
                        return <li key={i}>{company.name}</li>
                      })}
                    </ul>
                  </td>
                  <td headers="view-field-staff-table-column" className="views-field views-field-field-staff">
                    <ul>
                      {production.node.relationships.field_staff.map((member, i) => {
                        return <li key={i}>{member.name}</li>
                      })}
                    </ul>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export const query = graphql`
query ProduktionenPageQuery {
  site {
    ...SiteHeader
  }
  allNodeProduction(
    sort: {fields: [field_years___end_value, field_years___value], order: [DESC, DESC]}
  ) {
    edges {
      node {
        title
        field_years {
          value(formatString: "Y")
          end_value(formatString: "Y")
        }
        field_roles
        relationships {
          field_authors {
            name
          }
          field_staff {
            name
          }
          field_production_company {
            name
          }
        }
        field_link_in_views
        path {
          alias
        }
      }
    }
  }
}

`

export default ProduktionenPage